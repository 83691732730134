import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'dwa-basic-loader',
	templateUrl: './basic-loader.component.html',
	styleUrl: './basic-loader.component.scss',
})
export class BasicLoaderComponent {
	@HostBinding('hidden')
	public isHidden: boolean = false;

	@Input() public iconSize: string = '24';

	@Input() set isLoading(loading: boolean) {
		this.isHidden = !loading;
	}
}
