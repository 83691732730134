import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISideBarSection } from '../../types/sidebar.types';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'li[dwa-sidebar-section]',
	templateUrl: './sidebar-section.component.html',
	styleUrl: './sidebar-section.component.scss',
})
export class SidebarSectionComponent implements OnInit {
	@Input() public section!: ISideBarSection;
	@Output() public remove: EventEmitter<string>;
	public sectionTitle: string = '';

	constructor() {
		this.remove = new EventEmitter<string>();
	}

	public ngOnInit(): void {
		this.sectionTitle = this.describeDate(this.section.collectionDate);
	}

	public describeDate = (inputDate: string): string => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const compareDate = new Date(inputDate);
		compareDate.setHours(0, 0, 0, 0);

		if (compareDate.getTime() === today.getTime()) {
			return 'TODAY_LABEL';
		} else if (compareDate.getTime() === yesterday.getTime()) {
			return 'YESTERDAY_LABEL';
		} else {
			return inputDate;
		}
	};
}
