import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'dwa-robot-loader',
	templateUrl: './robot-loader.component.html',
	styleUrl: './robot-loader.component.scss',
})
export class RobotLoaderComponent {
	@HostBinding('hidden')
	public isHidden: boolean = false;

	@Input() set isLoading(loading: boolean) {
		this.isHidden = !loading;
	}
}
