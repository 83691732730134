import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MenuIconComponent } from './components/icons/menu-icon/menu-icon.component';
import { NgIconsModule } from '@ng-icons/core';
import {
	hugeMessageMultiple01,
	hugeFile01,
	hugeMessageAdd01,
	hugeLink04,
	hugeDatabase,
	hugeCopy01,
	hugeCancel01,
	hugeCancel02,
	hugeDocumentCode,
	hugeThumbsUp,
	hugeThumbsDown,
	hugeSent,
	hugeRobotic,
	hugeOrbit01,
} from '@ng-icons/huge-icons';
import { UserAvatarComponent } from './components/avatars/user-avatar/user-avatar.component';
import { BotAvatarComponent } from './components/avatars/bot-avatar/bot-avatar.component';
import { DefaultDividerComponent } from './components/default-divider/default-divider.component';
import { FormsModule } from '@angular/forms';
import { AutosizeTextareaDirective } from './directives/autosize-textarea.directive';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';

const usedIcons = {
	hugeMessageMultiple01,
	hugeDatabase,
	hugeFile01,
	hugeMessageAdd01,
	hugeLink04,
	hugeCopy01,
	hugeCancel01,
	hugeCancel02,
	hugeDocumentCode,
	hugeThumbsUp,
	hugeThumbsDown,
	hugeSent,
	hugeRobotic,
	hugeOrbit01,
};

@NgModule({
	declarations: [
		MenuIconComponent,
		UserAvatarComponent,
		BotAvatarComponent,
		DefaultDividerComponent,
		AutosizeTextareaDirective,
		ScrollToBottomDirective,
	],
	imports: [CommonModule, TranslateModule, NgIconsModule.withIcons(usedIcons), FormsModule],
	exports: [
		RouterModule,
		TranslateModule,
		MenuIconComponent,
		NgIconsModule,
		UserAvatarComponent,
		BotAvatarComponent,
		DefaultDividerComponent,
		AutosizeTextareaDirective,
		ScrollToBottomDirective,
	],
})
export class SharedModule {}
