import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { firstValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { ISideBarSection } from '../../types/sidebar.types';
import { ApiChatService } from '../../../shared/services/api/api-chat.service';
import { ILastChatsApi } from '../../../shared/types/api/api-chat.types';
import { LayoutService } from '../../services/layout.service';

@Component({
	selector: 'dwa-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnDestroy {
	private subscriber: ReplaySubject<void>;
	private lastChats?: ILastChatsApi;
	public sidebarSections: Array<ISideBarSection> = [];
	public initLoading: boolean = false;

	constructor(
		private sidebarService: SidebarService,
		private apiChatService: ApiChatService,
		private layoutService: LayoutService
	) {
		this.subscriber = new ReplaySubject<void>();
	}

	public get brandUrl(): string {
		return this.layoutService.brandLogo;
	}

	public ngOnInit(): void {
		this.initLoading = true;
		this.getSideBarLastChats()
			.then(sections => {
				this.sidebarSections = sections;
				this.initLoading = false;
			})
			.catch(() => (this.initLoading = false));
		this.listenToSidebarChanges();
	}

	private listenToSidebarChanges(): void {
		this.sidebarService.reloadSideBar$.pipe(takeUntil(this.subscriber)).subscribe(async () => {
			this.sidebarSections = await this.getSideBarLastChats();
		});
	}

	public async getSideBarLastChats(): Promise<Array<ISideBarSection>> {
		this.lastChats = await firstValueFrom(this.apiChatService.getLastChats());
		return this.transformLastChatsToSidebarSections(this.lastChats);
	}

	public removeChat(uuid: string) {
		const removeSubscription = this.apiChatService.deleteChat(uuid).subscribe({
			next: () => this.sidebarService.reload(),
			error: err => console.error(err),
		});
		removeSubscription.add(() => removeSubscription.unsubscribe());
	}

	private transformLastChatsToSidebarSections(chatHistory: ILastChatsApi): ISideBarSection[] {
		const sectionsMap: { [key: string]: ISideBarSection } = {};

		chatHistory.chats.forEach(chat => {
			const dateString = chat.updatedOn.split('T')[0];
			if (!sectionsMap[dateString]) {
				sectionsMap[dateString] = {
					name: dateString,
					collectionDate: dateString,
					items: [],
				};
			}
			sectionsMap[dateString].items.push({
				text: chat.title || 'No Title',
				uuid: chat.uuid,
			});
		});
		return Object.values(sectionsMap);
	}

	public get isSidebarOpen(): boolean {
		return this.sidebarService.isSidebarOpen;
	}

	public get isMobileSidebarOpen(): boolean {
		return this.sidebarService.isMobileSidebarOpen;
	}

	public closeMobileSidebar(): void {
		this.sidebarService.closeMobileSidebar();
	}

	public ngOnDestroy(): void {
		this.subscriber.next();
		this.subscriber.complete();
	}
}
