<aside
	[ngClass]="isSidebarOpen ? 'lg:flex' : ''"
	class="hidden border-r border-gray-200 bg-gray-100 transition-all lg:fixed lg:inset-y-0 lg:z-50 lg:w-80 lg:flex-col">
	<div class="flex items-center px-6 py-4">
		<div class="h-auto w-64 flex-initial">
			<img
				alt="Company Brand"
				src="{{ brandUrl }}"
				width="120"
				height="10"
				class="h-10 object-scale-down" />
		</div>
		<div>
			<button
				[routerLink]="['/', 'chat']"
				class="size-8 cursor-pointer place-content-center place-items-center rounded-full p-1 hover:bg-gray-200 hover:text-gray-800 hover:ring-1 hover:ring-gray-300">
				<ng-icon size="20" name="hugeMessageAdd01" />
			</button>
		</div>
	</div>
	<nav class="overflow-y-auto px-6 py-4">
		<dwa-basic-loader
			*ngIf="initLoading"
			[isLoading]="initLoading"></dwa-basic-loader>
		<ul
			role="list"
			class="flex flex-col space-y-1"
			*ngIf="sidebarSections && !initLoading">
			<li
				*ngFor="let section of sidebarSections"
				dwa-sidebar-section
				(remove)="removeChat($event)"
				[section]="section"></li>
		</ul>
	</nav>
</aside>
<aside
	[ngClass]="isMobileSidebarOpen ? 'relative' : 'hidden'"
	class="z-50 transition-all lg:hidden">
	<div class="fixed inset-0 bg-gray-900/80" aria-hidden="true"></div>

	<div class="fixed inset-0 flex">
		<div class="relative mr-16 flex w-full max-w-xs flex-1">
			<div class="absolute left-full top-0 flex w-16 justify-center pt-5">
				<button
					type="button"
					class="-m-2.5 p-2.5"
					(click)="closeMobileSidebar()">
					<span class="sr-only">Close sidebar</span>
					<svg
						class="h-6 w-6 text-white"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						aria-hidden="true">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
			<div
				class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-100 p-4 px-6 transition-all">
				<div class="flex h-10 items-center">
					<div class="h-auto w-64 flex-initial">
						<img
							alt="Company Brand"
							src="/assets/img/brand.svg"
							width="120"
							height="10"
							class="h-10 object-scale-down" />
					</div>
					<div class="flex-none">
						<ng-icon
							[routerLink]="['/', 'chat']"
							size="20"
							name="hugeMessageAdd01"
							class="shrink-0 cursor-pointer" />
					</div>
				</div>
				<nav>
					<ul role="list" class="flex flex-col space-y-1">
						<li
							*ngFor="let section of sidebarSections"
							dwa-sidebar-section
							[section]="section"></li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</aside>
